import React, { useEffect, useRef, useState } from "react";
import { LinearGradient } from "react-text-gradients";

import ETimg from "../images/EasyTrade.png";
import easyPose from "../images/easyPos.png";
import easyClub from "../images/easyPosClub.png";
import dash2 from "../images/newDash.png";
import AboutUs from "../images/aboutUs.jpg";
import Tick from "../images/tick.png";
import Testimonial from "./Testimonial.jsx";

import Map from "../images/gridMap.jpg";

import { Quick, EasyShop, Secure } from "../images/SvgIcon/Svg.jsx";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa6";

import ClientSlider from "./ClientSlider.jsx";
import ClientNameSlide from "./ClientNameSlide.jsx";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useNavigate } from "react-router-dom";
gsap.registerPlugin(ScrollTrigger);

export default function Home() {
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 slides at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonialRef1 = useRef(null);
  const testimonialRef2 = useRef(null);
  const testimonialRef3 = useRef(null);
  const testimonialRef4 = useRef(null);
  const testimonialRef5 = useRef(null);
  const testimonialRef6 = useRef(null);
  const testimonialRef7 = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const windowTopPosition = window.scrollY;
      const windowBottomPosition = windowTopPosition + windowHeight;

      const elements = [
        testimonialRef1.current,
        testimonialRef2.current,
        testimonialRef3.current,
        testimonialRef4.current,
        testimonialRef5.current,
        testimonialRef6.current,
        testimonialRef7.current,
      ];

      elements.forEach((element) => {
        if (!element) return;

        const elementHeight = element.offsetHeight;
        const elementTopPosition = element.offsetTop;
        const elementBottomPosition = elementTopPosition + elementHeight;

        if (
          elementBottomPosition >= windowTopPosition &&
          elementTopPosition <= windowBottomPosition
        ) {
          element.classList.add("in-view");
        } else {
          element.classList.remove("in-view");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);
  const totalItems = 3;

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % totalItems);
    }, 4500);

    return () => clearInterval(interval);
  }, []);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const handleNavClick = (direction) => {
    setActiveIndex((prevIndex) => {
      if (direction === "next") {
        return (prevIndex + 1) % totalItems;
      } else {
        return (prevIndex - 1 + totalItems) % totalItems;
      }
    });
  };

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  // Adjust the file path as needed
  const pdfFileUrl = "/Infrigment.pdf";

  // Function to open PDF in a new tab
  const openPdfInNewTab = () => {
    window.open(pdfFileUrl, "_blank");
  };

  const handleOpenPDF = () => {
    // Open the PDF in a new tab
    window.open("/sample.pdf", "_blank");
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="container-fluid mt-5">
      <div className="row pt-5">
        <div
          className="pt-3"
          style={{ fontSize: "3em", fontFamily: "Noto Sans", fontWeight: 600 }}
        >
          <LinearGradient gradient={["to left", "#17acff ,#ff68f0"]}>
            Be Different
          </LinearGradient>
        </div>
        <div
          style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 600 }}
        >
          <LinearGradient gradient={["to left", "#17acff ,#ff68f0"]}>
            Power Of Technology
          </LinearGradient>
        </div>
      </div>
      <div className="row d-flex align-items-center justify-content-center mb-5">
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 m-auto d-flex align-items-center justify-content-center">
          <img
            src={dash2}
            alt=""
            style={{ width: "100%", objectFit: "cover" }}
            className="animation-element slide-up testimonial"
            ref={testimonialRef2}
          />
        </div>
      </div>
      <div
        className="row mb-5 animation-element slide-up testimonial"
        ref={testimonialRef3}
      >
        <div className="col-lg-8 m-auto mb-4">
          <p
            style={{
              fontSize: "2.5em",
              fontFamily: "Noto Sans",
              fontWeight: 600,
            }}
          >
            <LinearGradient gradient={["to left", "#17acff ,#ff68f0"]}>
              Revolutionize Your Business with our Advanced Software.
            </LinearGradient>
          </p>
          <div
            style={{
              fontSize: "1.2em",
              fontFamily: "Noto Sans",
              fontWeight: 400,
              color: "#fff",
            }}
          >
            A complete Business Software and IT solutions partner.
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div
          className="row animation-element slide-up testimonial"
          ref={testimonialRef4}
        >
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <img
              src={AboutUs}
              alt=""
              style={{ width: "100%", objectFit: "contain" }}
              className=""
            />
          </div>
          <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12 ms-auto">
            <p
              className="py-3"
              style={{
                fontSize: "1.5em",
                fontFamily: "Noto Sans",
                fontWeight: 600,
                color: "#fff",
                textAlign: "left",
              }}
            >
              About Us
            </p>
            <p
              style={{
                fontSize: "1em",
                fontFamily: "Noto Sans",
                fontWeight: 400,
                color: "#B2BABB",
                textAlign: "left",
              }}
            >
              Helping Smart Business Succeed through the power of Busniess
              Application Software. As it the future of business in this digital
              age.
            </p>
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="d-flex justify-content-between align-items-center">
                  <p
                    className="pe-4"
                    style={{
                      fontSize: "4.5em",
                      fontFamily: "Noto Sans",
                      fontWeight: 600,
                      color: "#58D68D",
                      textAlign: "left",
                      filter: "blur(.8px)",
                    }}
                  >
                    11+
                  </p>
                  <p
                    style={{
                      fontSize: "1.2em",
                      fontFamily: "Noto Sans",
                      fontWeight: 400,
                      color: "#fff",
                      textAlign: "left",
                    }}
                  >
                    Years of Experience
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 me-auto">
                <div className="d-flex justify-content-between align-items-center">
                  <p
                    className="pe-4"
                    style={{
                      fontSize: "4.5em",
                      fontFamily: "Noto Sans",
                      fontWeight: 600,
                      color: "#58D68D",
                      textAlign: "left",
                      filter: "blur(.8px)",
                    }}
                  >
                    255+
                  </p>
                  <p
                    style={{
                      fontSize: "1.2em",
                      fontFamily: "Noto Sans",
                      fontWeight: 400,
                      color: "#fff",
                      textAlign: "left",
                    }}
                  >
                    Satisfied Clients
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className="row mb-4 animation-element slide-up testimonial"
          ref={testimonialRef5}
        >
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3">
            <div
              className="card p-4"
              //   onClick={handleOpenPDF}
              style={{
                backgroundColor: "#111212",
                width: "100%",
                boxShadow: "rgba(0,0,0,0.8)",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={Tick} alt="" style={{ width: "30%" }} className="" />
              <p
                className=""
                style={{
                  fontSize: "1.5em",
                  fontFamily: "Noto Sans",
                  fontWeight: 600,
                  color: "#fff",
                }}
              >
                User Friendly
              </p>
              <p
                className=""
                style={{
                  fontSize: "1em",
                  fontFamily: "Noto Sans",
                  fontWeight: 400,
                  color: "#B2BABB",
                }}
              >
                Easy to learn, use, understand,
              </p>
              <p
                className=""
                style={{
                  fontSize: "1em",
                  fontFamily: "Noto Sans",
                  fontWeight: 400,
                  color: "#B2BABB",
                }}
              >
                or to deal with.
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3">
            <div
              className="card p-4"
              style={{
                backgroundColor: "#111212",
                width: "100%",
                boxShadow: "rgba(0,0,0,0.8)",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={Tick} alt="" style={{ width: "30%" }} className="" />
              <p
                className=""
                style={{
                  fontSize: "1.5em",
                  fontFamily: "Noto Sans",
                  fontWeight: 600,
                  color: "#fff",
                }}
              >
                Simple
              </p>
              <p
                className=""
                style={{
                  fontSize: "1em",
                  fontFamily: "Noto Sans",
                  fontWeight: 400,
                  color: "#B2BABB",
                }}
              >
                Easy to understand.
              </p>
              <p
                className=""
                style={{
                  fontSize: "1em",
                  fontFamily: "Noto Sans",
                  fontWeight: 400,
                  color: "#B2BABB",
                }}
              >
                &nbsp;
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3">
            <div
              className="card p-4"
              style={{
                backgroundColor: "#111212",
                width: "100%",
                boxShadow: "rgba(0,0,0,0.8)",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={Tick} alt="" style={{ width: "30%" }} className="" />
              <p
                className=""
                style={{
                  fontSize: "1.5em",
                  fontFamily: "Noto Sans",
                  fontWeight: 600,
                  color: "#fff",
                }}
              >
                Effective
              </p>
              <p
                className=""
                style={{
                  fontSize: "1em",
                  fontFamily: "Noto Sans",
                  fontWeight: 400,
                  color: "#B2BABB",
                }}
              >
                Adequate to accomplish a purpose; producing the intended or
                expected result.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className="row animation-element slide-up testimonial"
          ref={testimonialRef6}
        >
          <div className="mb-5">
            <p
              className="text-center"
              style={{
                fontSize: "2em",
                fontFamily: "Noto Sans",
                fontWeight: 600,
              }}
            >
              <LinearGradient gradient={["to left", "#17acff ,#ff68f0"]}>
                Innovative Software for the Industry-Specific with Tailored
                Software Solutions
              </LinearGradient>
            </p>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3">
            <div
              className="card border-0 p-5 align-items-center prod-card"
              style={{ width: "100%", backgroundColor: "#111212" }}
            >
              <img src={ETimg} alt="" style={{ width: "65%" }} />
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3">
            <div
              className="card border-0 p-5 align-items-center prod-card"
              style={{ width: "100%", backgroundColor: "#111212" }}
            >
              <img src={easyPose} alt="" style={{ width: "100%" }} />
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3">
            <div
              className="card border-0 p-5 align-items-center prod-card"
              style={{ width: "100%", backgroundColor: "#111212" }}
            >
              <img src={easyClub} alt="" style={{ width: "69%" }} />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mb-4">
          <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 m-auto">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div>
                  <div
                    className="text-start"
                    style={{
                      fontSize: "1.5em",
                      fontFamily: "Noto Sans",
                      fontWeight: 500,
                      color: "#B2BABB",
                    }}
                  >
                    Intrested in Working
                  </div>
                  <div
                    className="text-start"
                    style={{
                      fontSize: "1.5em",
                      fontFamily: "Noto Sans",
                      fontWeight: 500,
                      color: "#B2BABB",
                    }}
                  >
                    Together ?
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div>
                  <div
                    className="text-start"
                    style={{
                      fontSize: "1em",
                      fontFamily: "Noto Sans",
                      fontWeight: 400,
                      color: "#B2BABB",
                    }}
                  >
                    &nbsp;
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-8 col-md-6 col-sm-12 mb-3">
                      <div
                        onClick={() => navigate("/Contact")}
                        className="proj-button d-flex align-items-center justify-content-center"
                      >
                        I am Intrested
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <button
                        onClick={() => navigate("/Product")}
                        className="lern-btn"
                      >
                        Learn More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className="row mb-4 animation-element slide-up testimonial"
          ref={testimonialRef7}
        >
          <div>
            <p
              className="text-center"
              style={{ fontSize: "3em", fontWeight: 600 }}
            >
              <LinearGradient gradient={["to left", "#17acff ,#ff68f0"]}>
                How it Works
              </LinearGradient>
            </p>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3">
            <div
              className="card border-0 p-5 align-items-center prod-card"
              style={{ width: "100%", backgroundColor: "#111212" }}
            >
              <p
                className="text-center fst-italic"
                style={{
                  fontSize: "3em",
                  fontFamily: "Noto Sans",
                  fontWeight: 600,
                  color: "#fff",
                }}
              >
                {" "}
                Examination
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3">
            <div
              className="card border-0 p-5 align-items-center prod-card"
              style={{ width: "100%", backgroundColor: "#111212" }}
            >
              <p
                className="text-center fst-italic"
                style={{
                  fontSize: "3em",
                  fontFamily: "Noto Sans",
                  fontWeight: 600,
                  color: "#fff",
                }}
              >
                {" "}
                Evaluation{" "}
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3">
            <div
              className="card border-0 p-5 align-items-center prod-card"
              style={{ width: "100%", backgroundColor: "#111212" }}
            >
              <p
                className="text-center fst-italic"
                style={{
                  fontSize: "3em",
                  fontFamily: "Noto Sans",
                  fontWeight: 600,
                  color: "#fff",
                }}
              >
                {" "}
                Execution{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div>
          <p
            className="text-center"
            style={{ fontSize: "3em", fontWeight: 600 }}
          >
            <LinearGradient gradient={["to left", "#17acff ,#ff68f0"]}>
              Our Clients
            </LinearGradient>
          </p>
        </div>
        <ClientSlider />
        <ClientNameSlide />
      </div>
      {/* <div className='container'>
                <div className='row my-4'>
                    <div className='text-center mt-5 pb-5' style={{ fontSize: "2em", fontWeight: 600, }}>
                        <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                           Testimonial
                        </LinearGradient>
                    </div>
                    <hr style={{ backgroundColor: "#B2BABB", height: "1px", border: "none", margin: 0 }} />
                </div>
                <Testimonial />
            </div> */}
      <button
        className="scroll-to-top-button border-0 rounded-2"
        onClick={scrollToTop}
        style={{
          width: 40,
          height: 40,
          backgroundColor: "#ff68f0",
          color: "#fff",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <IoArrowUpCircleOutline style={{ fontSize: 40 }} />
      </button>
    </div>
  );
}
